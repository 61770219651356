import { Pagination } from '@/helpers/CompositePagination'

export default () => ({
  events: [],
  eventsFe: [],
  unpassedCount: 0,
  pagination: new Pagination(),
  paginationFe: new Pagination(),
  isLoading: {
    events: false,
    eventsFe: false,
    btnMore: false,
    advertisementContent: false,
  },
  advertisementContent: {
    heading: null,
    body: null,
  },
})
