<template>
  <ElModal
    :name="name"
    title="Внимание, изменилось время в календаре!"
    :width="550"
  >
    <p class="font-size-16">
      Теперь в своем календаре вы будете видеть расписание тем и цели по вашему
      часовому поясу. Если часовой пояс определен неверно или вы хотите его
      изменить, это можно сделать в настройках
      <router-link :to="props.to" @click="onClickRoute">профиля</router-link>
    </p>

    <ElCheckbox
      v-model="agree"
      label="Не показывать снова"
      @update:model-value="onChange"
    />
  </ElModal>
</template>

<script setup>
// TODO: Удалить после 01.10.24
import ElCheckbox from '@/ui/ElCheckbox.vue'
import ElModal from '@/ui/ElModal.vue'
import { ref } from 'vue'
import { useVfm } from 'vue-final-modal'

const name = 'ModalNotifyTimezone'

const vfm = useVfm()

const props = defineProps({
  to: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits([
  'change',
])

const agree = ref(false)

/**
 *
 * @param {boolean} value
 */
async function onChange(value) {
  emit('change', value)
}

/**
 *
 */
function onClickRoute() {
  vfm.close(name)
}
</script>
