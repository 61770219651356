import { isMobileDomain } from '@/helpers/domain'

// Доступные ссылки навигации для клиента, если нет учеников (семейка или заочка)
const familyOrExtramuralEducationAvailableLinks = [
  'client-showcase',
  'client-courses',
  'client-contracts',
  'client-payment',
  'client-notifications',
  'client-online-school',
]

// Ссылки навигации для Семейного обучения
const familyEducationLinks = [
  ...familyOrExtramuralEducationAvailableLinks,
  'client-tariffs',
  'client-certification',
  'client-calendar',
  'client-goals',
  'client-news',
  'client-messages',
  'client-income-diagnostics',
  'client-statements',
  'client-teenology',
]

// Доступные ссылки навигации для клиента, если нет учеников (очка)
const fullTimeEducationAvailableLinks = [
  'client-showcase',
  'client-payment',
  'client-online-school',
  'client-notifications',
]

// Ссылки навигации для Очного обучения
const fullTimeEducationLinks = [
  'client-news',
  'client-library-enrollment',
  'client-library',
  'client-contracts',
  ...fullTimeEducationAvailableLinks,
  'client-courses',
  'client-certification',
  'client-statements',
  'client-schedule-lessons',
  'client-support',
  'client-messages',
  'client-income-diagnostics',
  'client-teenology',
]

// Ссылки навигации для Заочного обучения
const extramuralEducationLinks = [
  'client-contracts',
  'client-tariffs',
  'client-payment',
  'client-online-school',
  'client-certification',
  'client-lessons',
  'client-statements',
  'client-calendar',
  'client-goals',
  'client-news',
  'client-library-enrollment',
  'client-library',
  'client-support',
  'client-showcase',
  'client-courses',
  'client-messages',
  'client-income-diagnostics',
  'client-notifications',
  'client-teenology',
]

const linksExcludedOnMobile = [
  'client-contracts',
  'client-payment',
  'client-tariffs',
  'client-teenology',
]

const defaultLinks = [
  'client-showcase',
  'client-tariffs',
  'client-contracts',
  'client-online-school',
  'client-payment',
  'client-notifications',
]

/**
 * ID школ у которых не будут скрываться
 * ссылки указанные в переменной linksAvailableOnlyOnMainSchools
 */
const mainSchoolsIds = [
  1,
  4,
  3,
]

/**
 * наименования ссылок, которые будут скрываться
 * если клиент или ученик не относится к школам,
 * ID которых указанны в переменной mainSchoolsIds
 */
const linksAvailableOnlyOnMainSchools = [
  'client-lessons',
  'client-library-enrollment',
  'client-library',
  'client-teenology',
]

// Доступные ссылки навигации для клиента, если ученик не разблокирован и ему назначена входящая диагностика
const linksIncomeDiagnostics = [
  'client-contracts',
  'client-online-school',
  'client-payment',
  'client-income-diagnostics',
  'client-notifications',
  'client-library-enrollment',
  'client-showcase',
  'client-courses',
  'client-support',
]

const linksCommercialOffers = [
  'client-showcase',
  'client-courses',
]

/**
 * Список ссылок навигации, доступных для школ по id
 */
const linksAvailableBySchoolId = {
  21: [
    'client-teenology',
  ],
}

export default {
  // Список ссылок бокового меню
  getLinks(state, getters, rootState, rootGetters) {
    let hasCertifications = false

    const currentSchoolId = rootState.client.students.school.id
    const educationType = rootGetters['client/students/educationType']

    const fullTimeCertifications =
      rootState.client.fullTimeCertification.certifications
    const familyCertifications =
      rootState.client.familyCertification.certifications
    const extramuralCertifications =
      rootGetters['client/extramuralCertification/getAvailableCertifications']
    const isCurrentStudentDemo =
      rootGetters['client/students/isCurrentStudentDemo']
    const hasEnrolledStudents =
      rootGetters['client/students/hasEnrolledStudents']
    const hasContracts = rootGetters['client/contracts/hasContracts']
    const hasInvoices = rootGetters['client/invoices/hasInvoices']
    const hasModules = rootGetters['common/module/hasModules']
    const isCurrentSchoolIncluded = mainSchoolsIds.includes(currentSchoolId)
    const isStudentEnrolledAndHasDiagnostics =
      rootGetters['client/students/isStudentEnrolledAndHasDiagnostics']
    const isCurrentSudentEnrolled =
      rootGetters['client/students/isCurrentSudentEnrolled']
    const canStudentsJoinLibrary =
      rootGetters['client/students/canStudentsJoinLibrary']

    const hasDiagnosticsCurrentStudent =
      rootState.client.students.currentStudent?.hasIncomeDiagnostics ?? false
    const isAnyCommercialOfferAvailable =
      rootGetters['client/profile/isAvailableAnyCommercialOffer']

    const linksAvailableForCurrentSchool =
      linksAvailableBySchoolId[currentSchoolId] ?? []

    const hasCurrentStudentOnlineSchool =
      rootGetters['client/students/hasCurrentStudentOnlineSchool']

    const hasSubjectsExtracurricular =
      rootGetters['client/students/hasSubjectsExtracurricular']
    const hasSubjectsOnlineSchool =
      rootGetters['client/students/hasSubjectsOnlineSchool']

    const getNavLinks = () => {
      if (educationType.family) {
        return state.linksFamily
      }

      return state.links
    }

    let navLinks = getNavLinks()

    const checkCanStudentsJoinLibrary = () => {
      if (canStudentsJoinLibrary && !hasCurrentStudentOnlineSchool) {
        const hasNavLinksLibraryLink = navLinks.some(
          (link) => link.link.name === 'client-library-enrollment',
        )

        if (!hasNavLinksLibraryLink) {
          const libraryLink = state.links.find(
            (link) => link.link.name === 'client-library-enrollment',
          )

          navLinks.push(libraryLink)
        }
      }
    }

    const checkContractTariffLink = () => {
      const linkNameToExclude =
        !hasContracts &&
        hasModules([
          'online-school',
        ])
          ? 'client-contracts'
          : 'client-tariffs'

      navLinks = navLinks.filter((link) => link.link.name !== linkNameToExclude)
    }

    if (!isCurrentSudentEnrolled) {
      navLinks = navLinks.filter(
        (link) =>
          ![
            'client-library-enrollment',
            'client-library',
          ].includes(link.link.name),
      )
    }

    // Если отключены коммерческие предложения
    if (!isAnyCommercialOfferAvailable) {
      navLinks = navLinks.filter(
        (link) => !linksCommercialOffers.includes(link.link.name),
      )
    }

    // Если отключена входящая диагностика
    if (!hasDiagnosticsCurrentStudent) {
      navLinks = navLinks.filter(
        (link) => link.link.name !== 'client-income-diagnostics',
      )
    } else if (isStudentEnrolledAndHasDiagnostics) {
      navLinks = navLinks.filter((link) =>
        linksIncomeDiagnostics.includes(link.link.name),
      )
    }

    // Если ученик с демо-доступом
    if (isCurrentStudentDemo) {
      navLinks = navLinks.filter(
        (link) =>
          ![
            'client-support',
            'client-support-questions',
            'client-support-ideas',
            'client-support-faq',
            'client-support-contacts',
            'client-support-the-question',
            'client-support-the-idea',
          ].includes(link.link.name),
      )
    }

    // Если очный ученик и у клиента нет договоров/черновиков
    if (educationType.fullTime && !hasContracts) {
      navLinks = navLinks.filter(
        (link) => link.link.name !== 'client-contracts',
      )
    }

    if (
      !hasModules([
        'online-school',
      ])
    ) {
      navLinks = navLinks.filter(
        (link) => link.link.name !== 'client-online-school',
      )
    }

    if (hasEnrolledStudents) {
      if (educationType.fullTime) {
        navLinks = navLinks.filter((link) =>
          fullTimeEducationLinks.includes(link.link.name),
        )

        hasCertifications = fullTimeCertifications.length > 0
      } else {
        if (educationType.family) {
          navLinks = navLinks.filter((link) => {
            if (
              link.link.name === 'client-statements' &&
              !(hasSubjectsExtracurricular || hasSubjectsOnlineSchool)
            ) {
              return false
            }

            return familyEducationLinks.includes(link.link.name)
          })

          hasCertifications = familyCertifications.length > 0
        }

        if (educationType.extramural) {
          navLinks = navLinks.filter((link) =>
            extramuralEducationLinks.includes(link.link.name),
          )

          hasCertifications = extramuralCertifications.length > 0
        }
      }

      if (educationType.family) {
        // Если нет аттестаций
        if (!hasCertifications) {
          navLinks = navLinks.filter(
            (link) => link.link.name !== 'client-teenology',
          )
        }
      }

      if (!hasCertifications) {
        navLinks = navLinks.filter(
          (link) => link.link.name !== 'client-certification',
        )
      }

      if (isMobileDomain) {
        navLinks = navLinks.filter(
          (link) => !linksExcludedOnMobile.includes(link.link.name),
        )
      }

      /**
       * Если у клиента школа не Наши пенаты и не Олимп плюс,
       * то мы скрываем в меню кнопки "Уроки" и "События"
       */
      if (!isCurrentSchoolIncluded) {
        navLinks = navLinks.filter(
          (link) =>
            linksAvailableForCurrentSchool.includes(link.link.name) ||
            !linksAvailableOnlyOnMainSchools.includes(link.link.name),
        )
      }

      // Если нет доступов к разделу "Сообщения"
      if (
        !hasModules([
          'chat',
        ])
      ) {
        navLinks = navLinks.filter(
          (link) => link.link.name !== 'client-messages',
        )
      }

      // Если нет доступов к разделу "Поддержка"
      if (
        !hasModules([
          'support',
        ])
      ) {
        navLinks = navLinks.filter(
          (link) => link.link.name !== 'client-support',
        )
      }

      // Если подключен модуль "Витрина"
      if (
        !hasModules([
          'educational-showcase',
        ])
      ) {
        navLinks = navLinks.filter(
          (link) => link.link.name !== 'client-showcase',
        )
      }

      // Если подключен модуль "Библиотека"
      if (
        !hasModules([
          'school-library',
        ])
      ) {
        navLinks = navLinks.filter(
          (link) => link.link.name !== 'client-library',
        )
      }

      // Если подключен модуль "Курсы"
      if (
        !hasModules([
          'educational-courses',
        ])
      ) {
        navLinks = navLinks.filter(
          (link) => link.link.name !== 'client-courses',
        )
      }

      checkCanStudentsJoinLibrary()
      checkContractTariffLink()

      return navLinks
    }

    // Если выбранный ученик очный и не зачислен
    if (educationType.fullTime) {
      navLinks = navLinks.filter((link) =>
        fullTimeEducationAvailableLinks.includes(link.link.name),
      )
      // Если выбранный ученик заочный/семейный и не зачислен
    } else {
      if (hasContracts) {
        navLinks = navLinks.filter((link) =>
          familyOrExtramuralEducationAvailableLinks.includes(link.link.name),
        )
      } else {
        navLinks = navLinks.filter((link) =>
          defaultLinks.includes(link.link.name),
        )

        if (!hasInvoices) {
          navLinks = navLinks.filter(
            (link) => link.link.name !== 'client-payment',
          )
        }
      }
    }

    checkCanStudentsJoinLibrary()
    checkContractTariffLink()

    return navLinks
  },
}
