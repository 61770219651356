import ApiBase from 'ApiRest/ApiBase'

export default new (class MarkPastLessonsPassed extends ApiBase {
  /**
   * @param {Object} data
   * @returns {Promise}
   */
  put(data) {
    return this._PUT(`/account/student/lessons/mark-past-lessons-passed`, data)
  }
})()
