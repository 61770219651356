import dateFormat from '@/helpers/const/dateFormat'
import { getCookie } from '@/helpers/cookie'
import {
  add,
  addDays,
  addMilliseconds,
  addWeeks,
  endOfWeek,
  format,
  isDate,
  isValid,
  isWithinInterval,
  parseISO,
  startOfWeek,
} from 'date-fns'
import { formatInTimeZone, getTimezoneOffset, toZonedTime } from 'date-fns-tz'
import { enUS, he, ru } from 'date-fns/locale'
import { isNil } from 'lodash'

const { timeZone } = new Intl.DateTimeFormat().resolvedOptions()

export const formatOptions = {
  locale: ru,
}

switch (getCookie('lang')) {
  case 'en':
    formatOptions.locale = enUS
    break

  case 'he':
    formatOptions.locale = he
    break

  default:
    formatOptions.locale = ru
    break
}

export const offset =
  getTimezoneOffset(timeZone) - getTimezoneOffset('Europe/Moscow')

/**
 * Возвращает разницу (в миллисекундах) между часовым поясом, заданным в профиле пользователя и часовым поясом сервера (мск +3)
 * @param {Object} timezone
 * @throws {Error}
 * @returns {number}
 */
export const getOffsetFromDefaultTimezone = (timezone) => {
  if (isNil(timezone)) {
    throw new Error('Отсутстсвует параметр "timezone"')
  }

  return getTimezoneOffset(timezone.name) - getTimezoneOffset('Europe/Moscow')
}

/**
 * Возвращает разницу (в миллисекундах) между часовым поясом сервера (мск +3) и часовым поясом, заданным в профиле пользователя
 * @param {Object} timezone
 * @throws {Error}
 * @returns {number}
 */
export const getOffsetFromDefaultTimezoneBackwards = (timezone) => {
  if (isNil(timezone)) {
    throw new Error('Отсутстсвует параметр "timezone"')
  }

  return getTimezoneOffset('Europe/Moscow') - getTimezoneOffset(timezone.name)
}

/**
 * Возвращает отформатированную дату в часовом поясе, заданным в профиле пользователя, независимо от местного часового пояса системы
 * @param {Date|string|number} date
 * @param {Object} timezone
 * @param {string} formatStr
 * @returns {string}
 */
export const formatDateInTimeZone = (date, timezone, formatStr) =>
  formatInTimeZone(
    date,
    timezone.name,
    formatStr || `yyyy-MM-dd'T'HH:mm:ss`,
    formatOptions,
  )

/**
 * 0001-01-01 00:00:00 -> 0001-01-01T00:00:00
 * @param {string} value
 * @returns {string|null}
 */
export const formatToIso = (value) => {
  if (!value) {
    return value
  }

  return value.split(' ').join('T')
}

/**
 * @param {Date|string} value
 * @param {string} mask
 * @returns {string|null}
 */
const formatDate = (value, mask) => {
  if (!value) {
    return null
  }

  if (isDate(value)) {
    return format(value, mask, formatOptions)
  }

  return format(parseISO(formatToIso(value)), mask, formatOptions)
}

export default {
  /**
   * Форматирует дату согласно маске
   * @deprecated
   * @param {string} value
   * @param {string} formatValue
   * @returns {string|null}
   */
  getDate: (value, formatValue = dateFormat.DATE_FORMAT_FRONTEND) =>
    formatDate(value, formatValue),

  /**
   * Форматирует дату и время согласно маске
   * @deprecated
   * @param {string} value
   * @param {string} formatValue
   * @returns {string|null}
   */
  getDateTime: (value, formatValue = dateFormat.DATE_TIME_FORMAT_FRONTEND) =>
    formatDate(value, formatValue),

  /**
   * 0001-01-01 00:00:00 -> 0001-01-01T00:00:00
   * @deprecated
   * @param {string} value
   * @returns {string|null}
   */
  toIso(value) {
    if (!value) {
      return value
    }

    return value.split(' ').join('T')
  },
}

/**
 * Форматирует дату согласно маске
 * @param {Date|string} value
 * @param {string} formatValue
 * @returns {string|null}
 */
export const getDate = (value, formatValue = dateFormat.DATE_FORMAT_FRONTEND) =>
  formatDate(value, formatValue)

/**
 * Форматирует дату и время согласно маске
 * @param {string} value
 * @param {string} formatValue
 * @returns {string|null}
 */
export const getDateTime = (
  value,
  formatValue = dateFormat.DATE_TIME_FORMAT_FRONTEND,
) => formatDate(value, formatValue)

/**
 * Форматирует дату и время с учетом часового пояса из настроек профиля
 * @param {string | number | Date} date
 * @param {string} formatStr
 * @param {Object} timezone
 * @returns {string|null}
 */
export const formatWithOffset = (date, formatStr, timezone) => {
  const dateValue = isDate(date) ? date : parseISO(date)

  return format(
    addMilliseconds(dateValue, getOffsetFromDefaultTimezone(timezone)),
    formatStr,
    formatOptions,
  )
}

/**
 * Обратно форматирует дату и время из настроек часового профиля к дате и времени сервера (+3 мск)
 * @param {string | number | Date} date
 * @param {string} formatStr
 * @param {Object} timezone
 * @returns {string|null}
 */
export const formatWithOffsetBackwards = (date, formatStr, timezone) => {
  if (!date || !formatStr || !timezone) {
    return null
  }

  const dateValue = isDate(date) ? date : parseISO(date)

  return format(
    addMilliseconds(dateValue, getOffsetFromDefaultTimezoneBackwards(timezone)),
    formatStr,
    formatOptions,
  )
}

export const dateToZonedTime = (date, timezone) =>
  toZonedTime(date, timezone.name)

/**
 * Форматирует дату и время согласно маске
 * @param {string} value
 * @param {string} formatValue
 * @returns {string|null}
 */
export const getDateTimeSeconds = (
  value,
  formatValue = dateFormat.DATE_TIME_SECOND_FORMAT_FRONTEND,
) => formatDate(value, formatValue)

/**
 * Дата начала недели
 * @param {Date} date
 * @returns {Date}
 */
export const getStartOfWeek = (date) =>
  startOfWeek(date, {
    weekStartsOn: 1,
  })

/**
 * Дата последнего дня недели
 * @param {Date} date
 * @returns {Date}
 */
export const getEndOfWeek = (date) => endOfWeek(date)

/**
 * Получение даты спустя 7 дней
 * @param {Date} date
 * @returns {Date}
 */
export const getWeekLater = (date) =>
  getDate(addWeeks(date, 1), dateFormat.DATE_FORMAT_BACKEND)

/**
 * Дата последнего дня рабочей недели (пятница)
 * @param {Date} date
 * @returns {Date}
 */
export const getEndOfBusinessWeek = (date) => addDays(getStartOfWeek(date), 4)

/**
 * Сконвертировать Date в yyyy-MM-dd
 * (для текущей даты и периода fullcalendar)
 * @param {Date} date
 * @returns {string}
 */
export const dateToDay = function (date = new Date()) {
  return format(date, dateFormat.DATE_FORMAT_BACKEND)
}

/**
 * Сконвертировать yyyy-MM-dd в yyyy для текущего года календаря
 * @param {string} date
 * @returns {number}
 */
export const dayToYear = function (date = dateToDay()) {
  return Number(format(parseISO(date), 'yyyy'))
}

/**
 * Сконвертировать yyyy-MM в month yyyy
 * @param {string} yearMonth
 * @returns {string}
 */
export const yearMonthToFullMonthName = function (yearMonth) {
  return getDate(`${yearMonth}-01`, 'LLLL yyyy')
}

/**
 * @param {Object} obj
 * @param {string| Date} obj.timeStart
 * @param {string| Date} obj.timeEnd
 * @param {Object|null} obj.timeStartOffset
 * @param {Object|null} obj.timeEndOffset
 * @returns {boolean}
 */
export const isWithinIntervalTimeStartTimeEnd = ({
  timeStart,
  timeEnd,
  timeStartOffset = null,
  timeEndOffset = null,
}) => {
  // eslint-disable-next-line no-shadow
  const now = new Date().getTime()
  const date = new Date(now - offset)

  const tStart = isValid(timeStart) ? timeStart : parseISO(timeStart)
  const tEnd = isValid(timeEnd) ? timeEnd : parseISO(timeEnd)

  const start = timeStartOffset ? add(tStart, timeStartOffset) : tStart
  const end = timeEndOffset ? add(tEnd, timeEndOffset) : tEnd

  return isWithinInterval(date, {
    start,
    end,
  })
}

/**
 * @param {Date} date
 * @param {string} formatStr
 * @returns {number}
 * @todo Заменить возвращаемое значение на строку (не форматировать)
 */
export const getTimestamp = (date, formatStr = 'T') =>
  Number(formatInTimeZone(date, timeZone, formatStr))

/**
 * @param {string} date
 * @returns {string}
 */
export const getMonthName = (date) => {
  if (!date) {
    return ''
  }

  return parseISO(formatToIso(date)).toLocaleString(getCookie('lang') ?? 'ru', {
    month: 'long',
  })
}

/**
 * @param {string} date
 * @returns {string}
 */
export const getYear = (date) => {
  if (!date) {
    return ''
  }

  return parseISO(formatToIso(date)).getFullYear()
}

export const getDaysAfter = (
  date,
  daysNumber = 5,
  mask = dateFormat.DATE_FORMAT_BACKEND,
) => getDate(addDays(date, daysNumber), mask)
