import { showToast } from '@/helpers/toast'
import AdvertisementOnlineLesson from 'ApiRest/Api/Account/Student/UpcomingEvents/AdvertisementOnlineLesson'
import ApiUpcomingEventsList from 'ApiRest/Api/Account/Student/UpcomingEvents/List'
import ApiUnpassedCount from 'ApiRest/Api/Account/Student/UpcomingEvents/UnpassedCount'
import Constants from 'Constants'

export default {
  async fetchEvents(
    { commit },
    { page, types = [], date = null, educationType = null },
  ) {
    const filters = {
      page,
    }

    if (types.length > 0) {
      filters.types = types
    }

    if (date !== null) {
      if (date.dateStart !== null) {
        filters.dateStart = date.dateStart
      }

      if (date.dateEnd !== null) {
        filters.dateEnd = date.dateEnd
      }
    }

    if (educationType !== null) {
      filters.educationType = educationType
    }

    try {
      const { data, headers } = await ApiUpcomingEventsList.get(filters)

      commit('setEventsPagination', headers)
      commit('setEventsPage', page)
      commit('setEvents', data)
    } catch (error) {
      const message = error?.response?.data?.message

      if (message) {
        showToast(message, 'error')
      }
    } finally {
      commit('setLoading', {
        loadingFieldName: 'events',
        value: false,
      })
      commit('setLoading', {
        loadingFieldName: 'btnMore',
        value: false,
      })
    }
  },

  async fetchEventsFe({ commit }, { page, types = [] }) {
    const filters = {
      page,
      educationType: {
        id: Constants.educationType.FAMILY,
      },
    }

    if (types.length > 0) {
      filters.types = types
    }

    try {
      const { data, headers } = await ApiUpcomingEventsList.get(filters)

      commit('setEventsFePagination', headers)
      commit('setEventsFePage', page)
      commit('setEventsFe', data)
    } catch (error) {
      const message = error?.response?.data?.message

      if (message) {
        showToast(message, 'error')
      }
    } finally {
      commit('setLoading', {
        loadingFieldName: 'eventsFe',
        value: false,
      })
      commit('setLoading', {
        loadingFieldName: 'btnMore',
        value: false,
      })
    }
  },

  async fetchUnpassedCount({ commit }) {
    const { data } = await ApiUnpassedCount.get()

    commit('setUnpassedCount', data)
  },

  async fetchAdvertisementContent({ commit }, lessonId) {
    const { data } = await AdvertisementOnlineLesson.get(lessonId)

    commit('setAdvertisementContent', data)

    commit('setLoading', {
      loadingFieldName: 'advertisementContent',
      value: false,
    })
  },
}
