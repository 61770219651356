<template>
  <div class="mt-4 mb-4 mb-md-0 p-4 bg-white">
    <div
      class="d-flex flex-md-row flex-column justify-content-between align-items-md-center"
    >
      <div class="mb-3 mb-md-0">
        <h4 class="font-weight-bold">
          <span class="text-blue">{{ text('title') }}</span>
          {{ text('title_question') }}
        </h4>

        <div>{{ text('description') }}</div>
      </div>

      <ElButton class="btn-primary" @click="onClickBtnApply">
        {{ text('btn_apply') }}
      </ElButton>
    </div>
  </div>
</template>

<script setup>
import ModalApplyRequestSteps from '@/components/student/main/ModalApplyRequestSteps.vue'
import ElButton from '@/ui/ElButton.vue'
import { useModal } from 'vue-final-modal'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const { open, close } = useModal({
  component: ModalApplyRequestSteps,
  attrs: {
    onClose() {
      close()
    },
  },
})

const onClickBtnApply = () => {
  open()
}

/**
 * @param {string} name
 * @returns {string}
 */
function text(name) {
  return t(`components.main.apply_request_block.${name}`)
}
</script>
