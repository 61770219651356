import { Pagination } from '@/helpers/CompositePagination'
import { RESET_STATE } from '@/helpers/ResetState'
import createState from '@/store/student/journal/state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setEvents(state, payload) {
    state.events = [
      ...state.events,
      ...payload,
    ]
  },

  setEventsFe(state, payload) {
    state.eventsFe = [
      ...state.eventsFe,
      ...payload,
    ]
  },

  setUnpassedCount(state, payload) {
    state.unpassedCount = payload
  },

  setLoading(state, { loadingFieldName, value }) {
    state.isLoading[loadingFieldName] = value
  },

  clearEvents(state) {
    state.events = []
  },

  clearEventsFe(state) {
    state.eventsFe = []
  },

  resetPagination(state) {
    state.pagination = new Pagination()
  },

  resetPaginationFe(state) {
    state.paginationFe = new Pagination()
  },

  setEventsPagination(state, headers) {
    state.pagination.updateFromHeaders(headers)
  },

  setEventsFePagination(state, headers) {
    state.paginationFe.updateFromHeaders(headers)
  },

  setEventsPage(state, page) {
    state.pagination.setPage(page)
  },

  setEventsFePage(state, page) {
    state.paginationFe.setPage(page)
  },

  setAdvertisementContent(state, payload) {
    state.advertisementContent = payload
  },
}
