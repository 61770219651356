import ApiBase from 'ApiRest/ApiBase'

export default new (class AdvertisementOnlineLesson extends ApiBase {
  /**
   * @param {number} lessondId
   * @returns {Promise}
   */
  get(lessondId) {
    return this._GET(
      `/account/upcoming-events/advertisement-online-lesson/${lessondId}`,
    )
  }
})()
